
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import html2canvas from "html2canvas";
import { removeWhitespaceFromString, convertImageNameToAltText, removeTileImages } from '../scripts/helpers'
import KeyInfo from '../components/Profile/KeyInfo'
import Logo from '../components/Logo'
import { Helmet } from 'react-helmet'


//https://javascript.plainenglish.io/download-html-as-a-pdf-in-react-fc86114c9095
const downloadPageAsPdf = (containerDivId, filename) => {
  const containerDiv = document.getElementById(containerDivId);
  window.scrollTo(0,0)

  const containerDimensions = containerDiv.getBoundingClientRect()
  const width = containerDimensions.width
  const height = containerDimensions.height

  html2canvas(containerDiv, {
    useCORS: true
  })
    .then((canvas) => {
        const imageOfPageData = canvas.toDataURL('image/jpeg', 0.9);
        const { jsPDF } = require('jspdf')
        const pdf = new jsPDF({unit: 'px'}, {format: 'a4'});
        pdf.addImage(imageOfPageData, 'JPEG', 0, 0, width * 0.57, height * 0.57, 'FAST'); //0 values are padding
        pdf.save(filename);
    })
}


export default function Headcard({ data }) {

  const profileData = data.allContacts.edges[0].node
  const images = removeTileImages(data.allS3Object.nodes)

  const contactEmail = data.prismicHeaderFooter.data.footer_contact_email

  const fullname = profileData.fullname
  const pdfFileName = removeWhitespaceFromString(fullname) + '.pdf'

  const categories = profileData.categories ? profileData.categories.split(';') : []
  
  const maxImagesToDisplay = 3

  return (

      <div className="page-content headcard-page">

        <Helmet htmlAttributes={{
            lang: 'en',
          }}>
          <title>{fullname} Headcard</title>
          <meta name="description" content={`Headcard for ${fullname}`} />
        </Helmet>

        <div id="headcard-page-container">

        <div className="logo-banner">
          <Logo colour='black'/>
        </div>

        <div className="profile-heading">
          <h1>{fullname}</h1>
          { profileData.info.closestCentre &&
                    <h2>{profileData.info.closestCentre.split(';').join(', ')}</h2> }

          <ul className="profile-header-categories">
              {categories.map((category) => {
                  return (
                    <li key={category}>{category}</li>
                  )
                })}
          </ul>
        </div>


        <div className="profile-image-gallery">
          <div className="profile-image-container">
              {images.slice(0, maxImagesToDisplay).map((image) => {
                        return (
                          <GatsbyImage key={image.Key} image={getImage(image.localFile)}
                             alt={convertImageNameToAltText(image.Key, ' profile image')} />
                        )
                      })}
          </div>
        </div>

        <KeyInfo contact={profileData} />

        <div className='contact-email-container'>
          <span className="contact-email"><a href={`mailto:${contactEmail}`}>{contactEmail}</a></span>
        </div>

      </div>

      <div className='pdf-button-container'>
        <button className='button' onClick={() => downloadPageAsPdf('headcard-page-container', pdfFileName)}>Download PDF</button>
      </div>

    </div>
  )
}

export const query = graphql`
    query (
        $id: String!
        $imageRegex: String!
      )
      {
      allContacts (filter: {id: {eq: $id}})
        {
        edges {
          node {
            id
            fullname
            genders
            categories
            dateOfBirth
            measurements {
              chestBust
              height
              hip
              jeanSize
              kidsClothingSize
              shirtTopSize
              shoeSize
              waist
              seat
              head
            }
            info {
              city
              ethnicity
              closestCentre
              eyeColour
              hairColour
              nationality
            }
          }
        }
      }
      prismicHeaderFooter {
        data {
          footer_contact_email
        }
      }
      allS3Object(
        filter: {
          Key: {regex: $imageRegex},
          Size: {ne: 0}
        },
        limit: 3
        ) {
        nodes {
          Key
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 250, height: 350, breakpoints: [0], outputPixelDensities: [2] )
            }
          }
        }
      }
    }
  `